<template>
  <v-container class="pa-0">
    <v-row no-gutters class="pt-3">
      <v-col class="col-auto">
        <h1 class="headline font-weight-bold">{{ $t('product.payment') }}</h1>
      </v-col>
      <v-col align-self="center" class="pl-2">
        <v-icon size="22">mdi-lock</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat outlined>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            top
            color="accent"
          ></v-progress-linear>
          <v-container>
            <v-row>
              <v-col
                col-12
                class="pb-1 pt-2 subtitle-1 font-weight-bold primary--text"
                >{{ $t('product.credit_card') }}</v-col
              >
            </v-row>
            <v-row>
              <v-col col-12 class="pt-3 pb-1">
                <StripeCard
                  ref="stripeCard"
                  @change="cardInputChanged"
                ></StripeCard>
                <div
                  class="payment-information-errors d-flex justify-center align-center"
                >
                  <p
                    class="ma-0 pa-0 caption d-flex justify-center align-center"
                    v-if="paymentError !== null"
                  >
                    <v-icon size="13" color="error">mdi-alert-circle</v-icon>
                    {{ paymentError }}
                  </p>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col col-12 class="pt-0">
                <v-btn
                  block
                  class="text-none subtitle-1 font-weight-bold"
                  depressed
                  color="primary"
                  :disabled="disabledPaymentButton"
                  @click="onPaymentButtonClick"
                  data-cy="confirm-and-pay"
                >
                  {{ $t('product.confirm_and_pay') }}
                  {{ orderAmount.value | currency(orderAmount.baseCurrency) }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                col-12
                class="pt-3 pb-1 text-center caption terms-and-conditions-text"
              >
                {{ $t('product.by_tapping_you_agree') }}
                <br />
                <v-btn
                  class="pa-0 text-none caption font-weight-bold no-hover"
                  v-ripple="{ class: `transparent--text` }"
                  color="primary"
                  text
                  v-text="$t('product.terms_and_conditions')"
                  height="20px"
                  target="_blank"
                  :href="termsAndConditionsUrl"
                ></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import StripeCard from './StripeCard.vue';
export default {
  name: 'stripe-payment-component',
  components: { StripeCard },
  props: {
    orderAmount: {
      type: Object,
      default: () => ({ value: 0, currency: 'USD' }),
    },
    clientConfirmationKey: String,
    currency: {
      type: String,
      default: 'USD',
    },
    inProgress: Boolean,
  },
  data() {
    return {
      paymentInformationCompleted: false,
      paymentInformationError: null,
      paymentConfirmationError: null,
      handlingStripeActions: false,
      paymentMethodId: null,
    };
  },
  computed: {
    loading() {
      return this.inProgress || this.handlingStripeActions;
    },
    disabledPaymentButton() {
      const containsError = this.paymentInformationError ? true : false;
      return this.loading || !this.paymentInformationCompleted || containsError;
    },
    paymentError() {
      let errorMessage = null;
      if (this.paymentInformationError !== null) {
        errorMessage = this.paymentInformationError;
      } else if (this.paymentConfirmationError !== null) {
        errorMessage = this.paymentConfirmationError;
      }
      return errorMessage;
    },
    termsAndConditionsUrl() {
      return this.$store.getters[`termsAndConditionsUrl`];
    },
  },
  watch: {
    clientConfirmationKey(newValue) {
      if (newValue) {
        this.confirmPayment();
      }
    },
  },
  methods: {
    cardInputChanged(eventArgs) {
      this.paymentInformationCompleted = eventArgs.complete;
      if (eventArgs.error) {
        this.paymentInformationError = eventArgs.error.message;
      } else {
        this.paymentInformationError = null;
      }
    },

    onPaymentButtonClick() {
      this.paymentConfirmationError = null;
      this.$emit('clear-error');
      if (
        this.clientConfirmationKey !== null &&
        this.clientConfirmationKey !== undefined
      ) {
        this.confirmPayment();
      } else {
        this.submitPayment();
      }
    },

    async submitPayment() {
      this.handlingStripeActions = true;
      const isPaymentRetry =
        this.paymentMethodId !== null && this.paymentMethodId !== undefined
          ? true
          : false;
      try {
        const {
          paymentMethod,
          error,
        } = await this.$stripe.lib.createPaymentMethod(
          'card',
          this.$refs.stripeCard.card
        );
        if (error) {
          this.paymentInformationError = error.message;
        } else {
          this.paymentMethodId = paymentMethod.id;
          this.$emit('paymentMethodIdCreated', {
            paymentMethodId: this.paymentMethodId,
            requiresOrderCreation: isPaymentRetry,
          });
        }
      } catch (e) {
        this.paymentInformationError = e.message;
      } finally {
        this.handlingStripeActions = false;
      }
    },

    async confirmPayment() {
      this.handlingStripeActions = true;
      try {
        const { error } = await this.$stripe.lib.handleCardAction(
          this.clientConfirmationKey
        );

        if (error) {
          this.$emit('paymentConfirmationFailed');
          this.paymentConfirmationError = this.$t(
            'product.unable_to_confirm_payment_error'
          );
        } else {
          this.$emit('paymentConfirmed');
        }
      } catch (e) {
        this.paymentInformationError = e.message;
      } finally {
        this.handlingStripeActions = false;
      }
    },
  },
};
</script>
<style scoped>
.transparent-card {
  background-color: transparent;
}
.no-hover:before {
  background-color: transparent;
}
.stripe-card {
  border: 2px solid var(--v-primary-base);
  border-radius: 4px;
  padding: 5px 12px;
}
.payment-information-errors {
  height: 35px;
  line-height: 0.75rem;
  color: var(--v-error-base);
}
p.caption {
  line-height: 0.75rem;
}
.terms-and-conditions-text {
  color: #857f72;
  line-height: 0.7rem;
}
</style>
