<template>
  <v-container class="pa-0">
    <v-row no-gutters class="mt-6 mb-7">
      <v-col
        cols="12"
        class="d-flex align-start justify-space-between px-5 px-md-0"
      >
        <template v-if="travelers">
          <div
            v-for="(item, index) in travelers"
            :key="index"
            class="travelers-info mb-1"
          >
            <span class="travelers-count">
              {{ $t('product.traveler_num', { index: index + 1 }) }}
            </span>
            {{ travellerInfo(item) }}
          </div>
        </template>
        <template v-else>{{ $t('error') }}</template>
        <a
          class="d-flex align-center payment-return-link mt-1"
          @click="goToTraveler"
        >
          <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="
              M7.16634 3.66664H2.99967C2.55765 3.66664 2.13372 3.84223 1.82116 4.15479C1.5086
              4.46736 1.33301 4.89128 1.33301 5.33331V14.5C1.33301 14.942 1.5086 15.3659
              1.82116 15.6785C2.13372 15.991 2.55765 16.1666 2.99967 16.1666H12.1663C12.6084
              16.1666 13.0323 15.991 13.3449 15.6785C13.6574 15.3659 13.833 14.942 13.833
              14.5V10.3333M12.6547 2.48831C12.8084 2.32912 12.9923 2.20215 13.1957 2.1148C13.399
              2.02746 13.6177 1.98148 13.839 1.97956C14.0603 1.97763 14.2798 2.0198 14.4846
              2.1036C14.6894 2.1874 14.8755 2.31116 15.032 2.46765C15.1885 2.62414 15.3122 2.81022
              15.396 3.01505C15.4798 3.21988 15.522 3.43934 15.5201 3.66064C15.5182 3.88194 15.4722
              4.10064 15.3848 4.30398C15.2975 4.50732 15.1705 4.69123 15.0113 4.84497L7.85634
              12H5.49967V9.64331L12.6547 2.48831Z
            "
              style="stroke: var(--v-primary-base)"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="ml-1">
            {{ $t('edit') }}
          </span>
        </a>
      </v-col>
    </v-row>
    <slot name="paymentMethods" v-bind:paymentMethods="response">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <template v-if="error">
              <v-alert type="error" transition="bounceInRight">
                {{ error }}
              </v-alert>
            </template>

            <div class="payment-info mb-8 pt-6">
              <span class="d-block payment-info-title mb-2">
                {{ $t('product.payment_methods') }}
              </span>
              <PaymentList
                :savedCreditCards="savedCards"
                :methods="paymentMethods"
                :amount="amount"
                @clear-error="$emit('clear-error')"
                @create-alipayment="onAliButtonClick"
                @error="onError"
                @loading="onloading"
                @change="onListChange"
                @changeSavedCreditCardsSelection="onSavedCreditCardsSelection"
              />
            </div>
          </v-col>
        </v-row>

        <v-row no-gutters class="mb-4 px-5 px-md-0">
          <v-col cols="12" md="8">
            <PromoCodeForm
              dense
              :order-id="createdOrderId"
              :discountCoupon="discount"
              show-input-initial
              @submit:discountCoupon="updateDiscount"
              @clear:discountCoupon="removeDiscount"
              type="booking"
            />
          </v-col>
        </v-row>

        <template v-if="$vuetify.breakpoint.mdAndDown">
          <TermsAndConditionsLink class="mb-13" />
        </template>
      </v-container>
    </slot>
  </v-container>
</template>

<script>
import { RepoFactory } from '@/core/repositories';
import PaymentList from './PaymentsList.vue';
import PaymentMixins from '@/modules/payment/mixins/PaymentMixins';
import TravelerMixins from '@/modules/common/mixins/TravelerMixins';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';
import PromoCodeForm from '@/core/components/PromoCodeForm';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins';
import TermsAndConditionsLink from '@/core/components/TermsAndConditionsLink.vue';

export default {
  name: 'PaymentMethodsContainer',
  mixins: [
    PaymentMixins,
    TravelerMixins,
    ProductOrderInformationMixins,
    ProductCheckoutMixins,
  ],
  components: { TermsAndConditionsLink, PaymentList, PromoCodeForm },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    orderId: String,
    clientConfirmationKey: String,
  },

  data() {
    return {
      savePaymentMethodId: false,
      selectedMethod: null,
      selectedSavedCreditCard: null,
      selectedMethodExtras: null,
      allowedMethods: [
        'CreditCard',
        'AliPay',
        'WeChat',
        'ApplePay',
        'GooglePay',
      ],
      isLoadingInternal: false,
      response: null,
      error: null,
      enableButton: false,
      stripeCard: null,
      paymentConfirmationError: null,
      paymentMethodId: null,
      isAliPayMethodSelected: false,
    };
  },

  watch: {
    isLoading: {
      handler(loadingStatus) {
        this.$emit('loading-booking', loadingStatus);
      },
    },

    enableButton: {
      handler(val) {
        this.$emit('validate-booking', val);
      },
    },

    orderId: {
      immediate: true,
      handler(orderId, oldOrderId) {
        if (!!orderId && orderId !== '') {
          this.loadPaymentMethods(orderId);
          this.loadSavedCreditCards();
        }
      },
    },

    clientConfirmationKey(newValue) {
      if (newValue) {
        this.confirmCreditCardPayment(newValue);
      }
    },

    isAliPayMethodSelected(newValue) {
      if (newValue) {
        this.onAliButtonClick();
      }
    },
  },

  methods: {
    enablePersistence() {
      this.$store.dispatch(`featureFlags/enablePersistence`);
    },

    onError(error) {
      this.error = error;
    },

    onloading(isLoading) {
      this.isLoadingInternal = isLoading;
    },

    onSavedCreditCardsSelection(card) {
      this.selectedSavedCreditCard = card;
      if (this.selectedMethod === null) {
        this.enableButton = !!card;
      }
    },

    onListChange(method, extras) {
      if (method != null && extras != null) {
        this.selectedMethod = method;
        this.selectedMethodExtras = extras;
        this.enableButton = extras.completed;
        this.savePaymentMethodId = extras.savePaymentMethodId;
        this.isAliPayMethodSelected = !!extras.source?.alipay;
      } else {
        this.selectedMethod = null;
        this.selectedMethodExtras = null;
        this.enableButton = !!this.selectedSavedCreditCard;
        this.savePaymentMethodId = false;
      }
      if (extras?.id) {
        this.onPaymentButtonClick();
      }
    },

    loadSavedCreditCards(travelerId = this.travelerId) {
      if (travelerId) {
        this.loadSavedPaymentMethods(travelerId);
      }
    },

    loadPaymentMethods(orderId) {
      const orderService = RepoFactory.get('order', {
        resourceId: orderId,
      });
      this.loadPaymentMethodsFromService(orderService);
    },

    async loadPaymentMethodsFromService(orderService) {
      this.isLoadingInternal = true;
      try {
        const { data } = await orderService.getPaymentMethods();
        this.response = data;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoadingInternal = false;
      }
    },

    onPaymentButtonClick() {
      this.paymentConfirmationError = null;
      this.$emit('clear-error');
      // declare handlers obj for switching method handler
      const handlers = {
        CreditCard: this.creditCardClickHandler,
        GooglePay: this.submitCreditCardPayment,
        ApplePay: this.submitCreditCardPayment,
      };
      if (this.selectedMethod && handlers[this.selectedMethod]) {
        handlers[this.selectedMethod](this.selectedMethodExtras); // invoke the corresponding handler
      } else if (this.selectedSavedCreditCard) {
        this.$emit(
          'paymentConfirmed',
          this.selectedSavedCreditCard['paymentMethodId'],
          false
        );
      }
    },

    onAliButtonClick() {
      this.paymentConfirmationError = null;
      this.aliPayClickHandler(this.selectedMethodExtras);
    },

    creditCardClickHandler({ card, id }) {
      if (this.clientConfirmationKey != null) {
        this.confirmCreditCardPayment();
      } else {
        this.submitCreditCardPayment({ card, id });
      }
    },

    async confirmCreditCardPayment() {
      this.isLoadingInternal = true;
      try {
        const { error } = await this.$stripe.lib.handleCardAction(
          this.clientConfirmationKey
        );
        if (error) {
          this.$emit('paymentConfirmationFailed');
          this.error = this.$t('product.unable_to_confirm_payment_error');
        } else {
          this.$emit(
            'paymentConfirmed',
            this.paymentMethodId,
            this.savePaymentMethodId
          );
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoadingInternal = false;
      }
    },

    async submitCreditCardPayment({ card, id }) {
      this.isLoadingInternal = true;
      const isRetry = !!this.paymentMethodId;
      let paymentMethod, error;
      try {
        if (!id) {
          ({
            paymentMethod,
            error,
          } = await this.$stripe.lib.createPaymentMethod('card', card));
        }
        if (error) {
          this.error = error;
        } else {
          this.paymentMethodId = paymentMethod?.id ?? id;
          this.$emit('paymentMethodIdCreated', {
            paymentMethodId: this.paymentMethodId,
            requiresOrderCreation: isRetry,
            savePaymentMethodId: this.savePaymentMethodId,
          });
        }
      } catch (error) {
        this.error = error.message;
      } finally {
        this.isLoadingInternal = false;
      }
    },

    aliPayClickHandler({ source }) {
      this.enablePersistence();
      this.$emit('paymentMethodIdCreated', {
        paymentMethodId: `AliPay-${source.id}`,
        requiresOrderCreation: this.error,
        paymentSource: source,
        shouldRedirectTo: source.redirect.url,
      });
    },

    travellerInfo(item) {
      let { firstName, lastName, title, email, phone, phoneCode } = item;
      let { contactNum } = item;
      if (title) title = title[0] + title.slice(1).toLowerCase();

      if (!phone && !contactNum) {
        return `${firstName} ${lastName}${title ? `(${title})` : ''}, ${email}`;
      } else if (email) {
        return `${firstName} ${lastName}${
          title ? `(${title})` : ''
        }, ${email}, +${phoneCode ? phoneCode : contactNum.countryCode} ${
          phone ? phone : contactNum.value
        }`;
      } else {
        return `${firstName} ${lastName}`;
      }
    },

    goToTraveler() {
      this.$router.back();
      this.clearOrderState();
      this.$emit('clear-order-created');
    },
  },

  computed: {
    isLoading() {
      return this.isLoadingInternal || this.loading;
    },

    paymentMethods() {
      return !!this.response
        ? this.response.filter(
            (method) => this.allowedMethods.indexOf(method) > -1
          )
        : [];
    },

    hasPaymentError() {
      return !!this.paymentConfirmationError;
    },

    travelers() {
      if (this.$route.name === 'advica-payment') {
        return this.$store.getters['advica-store/getAllContacts'];
      } else {
        return this.getTravelers.filter((item) => {
          if (item.lastName) {
            return item;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .payment {
  &-button {
    padding: 12px 20px !important;
    border-radius: 8px;
    height: 54px !important;
    letter-spacing: 0px;
  }
  &-info {
    padding: 20px;
    background: var(--v-grey1-base);
    border-radius: 4px;

    @media (max-width: map-get($grid-breakpoints, 'sm')) {
      margin-bottom: 0;
    }

    &-title {
      @include font-size(18, 150, 700);
    }

    .v-expansion-panel {
      background: inherit;

      .v-expansion-panel-header,
      .v-expansion-panel-content__wrap {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }

  &-return {
    &-wrapper {
      @media (max-width: map-get($grid-breakpoints, 'sm')) {
        flex-direction: column-reverse;

        & > button {
          width: 100%;
          margin-bottom: 32px;
        }
      }
    }

    &-link {
      color: var(--v-grey9-base);
      text-decoration: none;
      @include font-size(14, 150, 600);
    }
  }
}

.travelers-info {
  @include font-size(16, 150, 400);

  & .travelers-count {
    @include font-size(16, 150, 600);
  }
}
</style>
