<template>
  <v-container fill-height fluid class="loading-container text-center mt-8">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <v-progress-circular
          class="mt-5"
          indeterminate
          color="#B8C1D1"
        ></v-progress-circular>
        <h2 class="my-5">{{ $t('product.please_wait') }}</h2>
        <span class="my-5">{{ $t('product.confirming_your_payment') }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PaymentLoading',
};
</script>

<style lang="scss" scoped>
.loading-container {
  background: #f2f4f7;
  border-radius: 4px;
  min-height: 270px;
}
</style>
