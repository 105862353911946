<template>
  <PaymentComponent
    ref="confirmBooking"
    @clear-order-created="$emit('clear-order-created')"
    @validate-booking="$emit('validate-booking', $event)"
    @loading-booking="(status) => $emit('loading-booking', status)"
    :localOrderId="localOrderId"
    :orderRequest="orderRequest"
    :enablePaymentMethods="true"
  ></PaymentComponent>
</template>

<script>
import PaymentComponent from '@/modules/product/features/Payment/PaymentComponent.vue';
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins.vue';

export default {
  name: 'PaymentMethodsPage',
  props: {
    localOrderId: String,
  },
  components: { PaymentComponent },
  mixins: [ProductCheckoutMixins],
  methods: {
    onPaymentButtonClick() {
      this.$refs.confirmBooking.onPaymentButtonClick();
    },
  },
};
</script>
