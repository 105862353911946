<template>
  <v-container class="ma-0 pa-0" v-if="enablePaymentMethods">
    <v-alert
      class="payment-error"
      :value="hasOrderPaymentError && showError"
      type="error"
      outlined
      text
      icon="mdi-close-circle"
      transition="bounceInRight"
      ><v-row no-gutters class="title payment-error font-weight-bold"
        >Payment Failed</v-row
      ><v-row no-gutters class="details payment-error pt-1">{{
        orderPaymentError
      }}</v-row></v-alert
    >
    <PaymentLoading v-if="ispollingCheckout" />
    <PaymentMethodsContainer
      v-else
      ref="bookingConfirmation"
      :orderId="createdOrderId"
      :loading="loading"
      :clientConfirmationKey="clientConfirmationKey"
      @clear-order-created="$emit('clear-order-created')"
      @validate-booking="$emit('validate-booking', $event)"
      @loading-booking="(status) => $emit('loading-booking', status)"
      @clear-error="showError = false"
      @paymentMethodIdCreated="paymentMethodCreated"
      @paymentConfirmed="submitOrderPayment"
    ></PaymentMethodsContainer>
  </v-container>
  <div class="ma-0 pa-0" v-else>
    <!-- legacy support -->
    <v-alert
      :value="hasOrderPaymentError"
      type="error"
      transition="bounceInRight"
      >{{ orderPaymentError }}</v-alert
    >
    <CreditCardPayment
      :orderAmount="amount"
      :clientConfirmationKey="clientConfirmationKey"
      :inProgress="loading"
      @clear-error="showError = false"
      @paymentMethodIdCreated="paymentMethodCreated"
      @paymentConfirmed="submitOrderPayment"
    ></CreditCardPayment>
  </div>
</template>

<script>
import CreditCardPayment from './CreditCardPayment.vue';
import ProductCheckoutMixins from '../../mixins/ProductCheckoutMixins.vue';
import PaymentMethodsContainer from './PaymentMethodsContainer.vue';
import PaymentLoading from './PaymentLoading.vue';
import GoogleTagManagerMixins from '@/core/mixins/GoogleTagManagerMixins';
import ProductOrderInformationMixins from '@/modules/product/booking/mixins/ProductOrderInformationMixins';

export default {
  name: 'PaymentComponent',
  mixins: [
    ProductCheckoutMixins,
    GoogleTagManagerMixins,
    ProductOrderInformationMixins,
  ],
  components: {
    CreditCardPayment,
    PaymentMethodsContainer,
    PaymentLoading,
  },
  props: {
    localOrderId: {
      required: true,
    },
    enablePaymentMethods: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isRetryingOrderCheckout: false,
      ispollingCheckout: false,
      shouldRedirectTo: null,
      showError: false,
    };
  },
  methods: {
    onPaymentButtonClick() {
      this.$refs.bookingConfirmation.onPaymentButtonClick();
    },
    async paymentMethodCreated({
      paymentMethodId,
      requiresOrderCreation,
      paymentSource,
      shouldRedirectTo = null,
      savePaymentMethodId = false,
    }) {
      if (requiresOrderCreation || this.hasOrderCheckoutError) {
        this.isRetryingOrderCheckout = true;
        await this.createOrder({
          order: this.orderRequest,
          localOrderId: this.localOrderId,
        });
      }
      this.shouldRedirectTo = shouldRedirectTo;
      await this.submitOrderPayment(
        paymentMethodId,
        paymentSource,
        savePaymentMethodId
      );
    },
    async submitOrderPayment(
      paymentMethodId,
      paymentSource,
      savePaymentMethodId = false
    ) {
      const checkoutPayload = {
        orderId: this.createdOrderId,
        paymentMethodId: paymentMethodId,
        save: savePaymentMethodId,
        discountToken: this.createdDiscountToken,
      };
      if (paymentSource && paymentSource.flow === 'redirect') {
        // redirect flow requires async checkout
        checkoutPayload.asyncCall = true;
        checkoutPayload.sourceId = paymentSource.id;
        delete checkoutPayload.paymentMethodId;
        checkoutPayload.paymentMethodType = paymentSource.type;
      }
      if (this.$route.meta && this.$route.meta.requiresPoll) {
        checkoutPayload.asyncCall = true;
      }
      await this.checkOutOrder(checkoutPayload);
      if (!this.hasOrderPaymentError) {
        let orderAnalytics = {
          productId: this.productId,
          date: this.selectedAvailabilityDate,
          option: this.selectedOption,
          price: this.amount.value,
          passes: this.productPasses,
          type: 'booking',
        };
        if (this.$route.query.welcome) {
          this.pushWelcomePurchase(orderAnalytics, this.createdOrderId);
        } else {
          this.pushExperiencesPurchase(orderAnalytics, this.createdOrderId);
        }
      }
      this.showError = true;
    },
    clearOrderCheckout() {
      this.isRetryingOrderCheckout = false;
      this.clearOrderCheckOutState();
      this.clearOrderCreationState();
    },
    enableOrderpolling() {
      this.ispollingCheckout = true;
      this.startCheckoutpolling({
        order: this.orderRequest,
        orderId: this.createdOrderId,
        localOrderId: this.localOrderId,
      });
    },
    goToStatusPage() {
      this.isRetryingOrderCheckout = true;
      this.ispollingCheckout = false;
      this.$router.push({ name: `${this.$route.meta.baseName}-status` });
    },
  },
  computed: {
    loading() {
      return this.isLoadingOrder || this.isRetryingOrderCheckout;
    },
    clientConfirmationKey() {
      return this.checkOutConfirmationKey;
    },
  },
  watch: {
    orderCheckoutTimeout(hasTimeout) {
      if (hasTimeout) {
        this.goToStatusPage();
      }
    },
    checkoutStatus: {
      handler(status) {
        if (status == null) return;
        if (this.shouldRedirectTo) {
          const redirect = this.shouldRedirectTo;
          this.shouldRedirectTo = null;
          window.location = redirect;
        } else if (this.isAsyncCheckout) {
          return;
        } else if (status === 'Confirmed' || status === 'Pending') {
          this.goToStatusPage();
        } else {
          this.isRetryingOrderCheckout = true;
          this.clearOrderState();
          this.createOrder({
            order: this.orderRequest,
            localOrderId: this.localOrderId,
          });
        }
      },
    },
    isAsyncCheckout: {
      immediate: true,
      handler(newValue) {
        if (!!newValue) {
          this.enableOrderpolling();
        }
      },
    },
    hasCreatedOrder(newValue) {
      if (newValue) {
        this.submitOrderPayment();
      }
    },
    hasOrderCreationError(newValue) {
      if (newValue) {
        this.isRetryingOrderCheckout = false;
      }
    },
    hasOrderCheckoutError(newValue) {
      if (newValue) {
        this.isRetryingOrderCheckout = false;
        this.ispollingCheckout = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-error {
  color: black;
  &.title {
    margin-top: -5px;
  }
  &.details {
    margin-left: -35px;
  }
}
</style>
